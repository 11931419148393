import Link from 'next/link';

import { PAGE_BLOG, PAGE_BLOG_POST } from '@root/constants/routes';

import type { BlogIndexDownstreamProps } from '@root/pages/blog';
import BlogPagination from 'components/Blog/Pagination';
import BlogPicture from 'components/Blog/Picture';
import { excerpt, formatPublishedAt } from 'components/Blog/utils';
import Card from 'components/Card';
import IconArrowRight from 'components/IconArrowRight';

const BlogIndex = ({ activeBlogCategory, posts, totalPages, updatePage }: BlogIndexDownstreamProps) => {
  const [primary, ...frontPosts] = posts;

  return (
    <>
      <section className="container">
        <Link href={PAGE_BLOG_POST} as={`${PAGE_BLOG}/${activeBlogCategory.slug!.current}/${primary.slug?.current}`}>
          <a className="row group relative mb-64 grid-cols-1 bg-white shadow-3 hover:no-underline md:grid-cols-12">
            <BlogPicture
              slot="indexHero"
              url={primary.mainImage.asset.url}
              alt={`${primary.mainImage.alt} thumbnail`}
              className="col-span-1 md:col-span-7 md:aspect-ratio-7by5 lg:aspect-ratio-7by5"
              crop
              nativeWidth={primary.mainImage.asset.metadata.dimensions?.width!}
            />

            <div className="col-span-1 flex flex-col justify-center space-y-24 p-32 md:col-span-5 md:pl-0">
              <h2 className="text-h2-display duration-150 group-hover:text-gold-darker">{primary.title}</h2>
              <div>
                {primary.author && (
                  <span className="font-['Roboto Condensed Bold'] text-sm mr-8 font-bold leading-none tracking-wide">
                    {primary.author.name}
                  </span>
                )}
                <span className="text-xs">{formatPublishedAt(primary.publishedAt ?? '')}</span>
              </div>
              <div className="text-gray-dark duration-150 group-hover:text-gold-darker">{excerpt(primary)}</div>

              <div>
                <div className="btn-info btn">
                  {'Read More'} <IconArrowRight />
                </div>
              </div>
            </div>
          </a>
        </Link>
      </section>

      <section className="m-section container">
        <div className="space-y-32 md:space-y-64">
          <div className="row mb-64 gap-y-32">
            {frontPosts.map((post) => {
              const trackerCls = `tracker-callout-blog-post_${post.slug?.current.replace(/-/g, '_')}-210920-134950`;

              return (
                <Card
                  caption={formatPublishedAt(post.publishedAt ?? '')}
                  className="col-span-12 sm:col-span-6 md:col-span-4"
                  ctaTitle="Read more"
                  subTitle={post?.author?.name}
                  as={`${PAGE_BLOG}/${activeBlogCategory.slug!.current}/${post.slug?.current}`}
                  href={PAGE_BLOG_POST}
                  image={
                    <BlogPicture
                      alt={`${post.mainImage.alt} thumbnail`}
                      className="col-span-1 aspect-ratio-7by5 md:col-span-8"
                      crop
                      nativeWidth={post.mainImage.asset.metadata.dimensions?.width!}
                      slot="indexCard"
                      url={post.mainImage.asset.url}
                    />
                  }
                  key={post._id}
                  title={String(post.title)}
                  trackerClass={trackerCls}
                >
                  <div className="text-gray-dark duration-150 group-hover:text-gold-darker">{excerpt(post)}</div>
                </Card>
              );
            })}
          </div>
        </div>

        <BlogPagination
          activeBlogCategory={activeBlogCategory.slug.current}
          page={1}
          btnNext={
            <div className="text-right">
              <button className="btn-info btn" onClick={() => updatePage({ category: activeBlogCategory, page: 2 })}>
                {'More Blogs'} <IconArrowRight />
              </button>
            </div>
          }
          btnPrev={false}
          totalPages={totalPages}
          updatePage={updatePage}
        />
      </section>
    </>
  );
};

export default BlogIndex;
