import React from 'react';
import SalesCallout from './SalesCallout';

interface SalesCalloutSwatchesProps {
  ctaPageName: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLAnchorElement> & SalesCalloutSwatchesProps>;

const SalesCalloutSwatches: Props = ({ className, ctaPageName, ...props }) => {
  return (
    <SalesCallout
      title="Free Swatches"
      ctaTitle="See Offer"
      calloutId="sales_callout_swatches"
      ctaPageName={ctaPageName}
      href="/swatches"
      {...props}
      className={`${className ? className : ''}`}
      image={
        <div className="inline-block max-w-full">
          <picture>
            <img
              className="block h-auto max-w-full align-middle"
              width={242}
              height={206}
              loading="lazy"
              src="https://gentux.imgix.net/1673983552_Fall22Swatches.png?fit=crop&crop=focalpoint&fp-y=.25&fp-z=1.1&fp-x=.4&w=244&h=134&auto=format&dpr=2"
              alt="Generation Tux swatch cards."
            />
          </picture>
        </div>
      }
    >
      Need help with color matching? We'll send you complimentary swatches.
    </SalesCallout>
  );
};

export default SalesCalloutSwatches;
