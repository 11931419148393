import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import type { BlogPostFragment, SeoData } from 'types';
import type { BlogCategory } from '@root/sanity/sanity.types';
import { PAGE_BLOG } from '@root/constants/routes';
import { BLOG_POSTS_INDEX, BLOG_POSTS_PER_PAGE } from '@root/constants/blog';
import BlogIndex from 'components/Blog';
import BlogList from 'components/Blog/List';
import BlogPagination from 'components/Blog/Pagination';
import LayoutBlog from 'layout/Blog';
import { BLOG_BASE_CANONICAL_URL } from 'components/Blog/utils';
import NextSteps from 'components/NextSteps';
import SalesCalloutSwatches from 'components/SalesCalloutSwatches';
import SalesCalloutInspiration from 'components/SalesCalloutInspiration';
import { getAllBlogCategories, getBlogIndex } from 'services/blog';
import { withPageStaticProps } from 'utils/header';
import { getParameterByName } from 'utils/utils';

/**
 * Total pages for blog index page is different since on the index page we show 10 posts (1 featured and 9 posts),
 * all other pages show a list of 12 posts
 */
export const getTotalPages = (totalPosts: number) =>
  Math.ceil((totalPosts - BLOG_POSTS_INDEX) / BLOG_POSTS_PER_PAGE) + 1;

type BlogPageProps = {
  activeBlogCategory: BlogCategory;
  blogCategories: BlogCategory[];
  canonicalUrl: string;
  error: boolean;
  posts: BlogPostFragment[];
  seo: SeoData;
  title: string;
  totalPosts: number;
};

export type BlogIndexDownstreamProps = BlogPageProps & {
  page: number;
  totalPages: number;
  updatePage: (options: { category?: BlogCategory; page: number }) => void;
};

/**
 * Blog index page
 */
const BlogPage = ({ posts, ...props }: BlogPageProps) => {
  const [page, setPage] = useState(1);
  const router = useRouter();

  useEffect(() => {
    const qsPage = getParameterByName('page') ? Number(getParameterByName('page')) : page;

    if (qsPage && qsPage !== page) {
      setPage(qsPage);
    }

    if (!('page' in router.query)) {
      //We go straight back to page one if there is no page query param
      setPage(1);
    }
  }, [router.query]);

  const getRenderedPosts = () => {
    let offsetStart = 0;
    let offsetEnd = Number(BLOG_POSTS_INDEX);

    if (page > 1) {
      const indexPostsCountCorrection = Math.abs(BLOG_POSTS_PER_PAGE - BLOG_POSTS_INDEX);
      offsetStart = (page - 1) * BLOG_POSTS_PER_PAGE - indexPostsCountCorrection;
      offsetEnd = offsetStart + BLOG_POSTS_PER_PAGE;
    }

    return posts.slice(offsetStart, offsetEnd);
  };

  const handleUpdatePage = ({ page }: { category?: BlogCategory; page: number }) => {
    router.push({
      query: {
        page,
      },
    });

    setPage(page);
  };

  const { totalPosts } = props;
  const totalPages = getTotalPages(totalPosts);
  const renderedPosts = getRenderedPosts();

  return (
    <>
      <Head>
        <title>{props.title ?? 'Generation Tux Blog'}</title>
      </Head>
      <LayoutBlog categories={props.blogCategories} pathname={PAGE_BLOG} showLogo={true}>
        {page > 1 ? (
          <>
            <BlogList activeBlogCategory={props.activeBlogCategory.slug.current} posts={renderedPosts} />

            <BlogPagination
              activeBlogCategory={props.activeBlogCategory.slug.current}
              page={page}
              totalPages={totalPages}
              updatePage={handleUpdatePage}
            />
          </>
        ) : (
          <BlogIndex
            {...props}
            posts={renderedPosts}
            page={page}
            totalPages={totalPages}
            updatePage={handleUpdatePage}
          />
        )}
      </LayoutBlog>

      <NextSteps
        ctaPageName="blog"
        salesCallout1={<SalesCalloutSwatches ctaPageName="blog" />}
        salesCallout2={<SalesCalloutInspiration ctaPageName="blog" />}
      />
    </>
  );
};

export const getStaticProps = withPageStaticProps(async () => {
  try {
    const activeBlogCategory = {
      categoryName: 'All Blogs',
      slug: {
        current: 'all',
      },
    };
    const blogCategories = await getAllBlogCategories();
    const response = await getBlogIndex();
    const totalPosts = response.allBlogPost.length;
    const title = response.allBlogSettings[0].title;

    return {
      props: {
        activeBlogCategory,
        blogCategories,
        canonicalUrl: BLOG_BASE_CANONICAL_URL,
        error: false,
        posts: response.allBlogPost,
        seo: {
          title,
          metaDescription: title,
          slug: PAGE_BLOG,
        },
        title,
        totalPosts,
      },
    };
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get blog posts');
  }
});

export default BlogPage;
