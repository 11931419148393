import React from 'react';
import SalesCallout from './SalesCallout';

interface SalesCalloutInspirationProps {
  ctaPageName: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLAnchorElement> & SalesCalloutInspirationProps>;

const SalesCalloutInspiration: Props = ({ className, ctaPageName, ...props }) => {
  return (
    <SalesCallout
      title="Need Inspiration?"
      ctaTitle="Get Inspired"
      calloutId="sales_callout_inspiration"
      ctaPageName={ctaPageName}
      href="/gallery"
      {...props}
      className={`${className ? className : ''}`}
      image={
        <div className="inline-block max-w-full">
          <picture>
            <img
              className="block h-auto max-w-full align-middle"
              width={240}
              height={206}
              loading="lazy"
              src="https://gentux.imgix.net/1608153917_GraySharkskinNotchSuitAlt220201107.png?auto=format&fit=crop&crop=focalpoint&w=240&h=206&fp-x=.5&fp-y=.45&fp-z=1.2"
              alt="Generation Tux model in stylish suit rental"
            />
          </picture>
        </div>
      }
    >
      We’ve got style. Take a look at our suits and tuxes in&nbsp;action.
    </SalesCallout>
  );
};

export default SalesCalloutInspiration;
